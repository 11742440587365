import { API_400_CODES } from 'utils/Constants';

export async function fetchCampaignData(UserId:string, UserToken:string, ClientId:string, CampaignId:string) {
  const endpoint = process.env.REACT_APP_GEOTRAK_CAMPAIGN_ENDPOINT || '';
  const response = await fetch(
    endpoint,
    {
      method: 'POST',
      body: JSON.stringify({UserId, UserToken, ClientId, CampaignId})
    }
  );

  if (response.status >= 500) {
    // log response.statusText on Sentry
    // This should ultimately be:
    // { status: response.status, error: true, message: API_500_CODES.SERVER_ERROR };
    // but we first need a more detailed response.
    // A bad copy&paste (campaign ID missing a character) will result in 500
    // and we wanna send the user to the login page.
    return { status: response.status, error: true, message: API_400_CODES.CAMPAIGN_AUTH_FAIL };
  }

  if (response.status >= 400) {
    // log response.statusText on Sentry
    return { status: response.status, error: true, message: API_400_CODES.SERVICE_OFFLINE };
  }

  const data = await response.json();

  if (data.CampaignAuth !== 'Success' && data.UserAuth === 'Success') {
    return { status: response.status, error: true, message: API_400_CODES.CAMPAIGN_AUTH_FAIL };
  }

  // This user auth doesn't refer to the app user, but to the client
  if (data.UserAuth !== 'Success' && data.CampaignAuth === 'Success') {
    return { status: 401, error: true, message: API_400_CODES.CLIENT_AUTH_FAIL };
  }

  // TODO
  // Problem here is that CampaignAuth and UserAuth will come as Failure with the message 'Removed Token'
  // in too many scenarios
  // · The token doesn't exist
  // · The client ID in the URL is wrong
  // · The user is locked
  // · The session is expired
  //
  // We need to at least differentiate between client ID issues and session issues
  if ((data.CampaignAuth !== 'Success' || data.UserAuth !== 'Success') && data.Message?.toLowerCase().includes('removed token')) {
    return { status: response.status, error: true, message: API_400_CODES.SESSION_EXPIRED };
  }

  return {...data.CampaignSummary, PlanSummary: data.PlanSummary, PanelSummary: data.PanelSummary };
}

const CampaignSummaryService = {
  fetchSampleData: fetchCampaignData,
}

export default CampaignSummaryService;
