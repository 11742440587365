import { createContext } from 'react';
import { types, Instance } from 'mobx-state-tree';

import AuthenticationStore, { IAuthentication } from 'models/Auth/Auth';
import CampaignSummary, { ICampaignSummary } from 'models/CampaignSummary/CampaignSummaryModel';
import { IPanelSummary } from 'models/PanelSummary/PanelSummary';

export const RootStore = types.model({
	Authentication: types.optional(AuthenticationStore, {} as IAuthentication),
	CampaignSummary: types.optional(CampaignSummary, {} as ICampaignSummary),
	GoogleMapsAPIKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
	isPhotoSheetOpen: false,
	isMapFullscreen: false,
  // When a user opens a photo sheet from the locations table
	selectedPanelId: types.maybeNull(types.string),
  // When a user clicks on a marker in the map on the summary section
	selectedSummaryMapMarker: types.maybeNull(types.string),
})
.actions((self) => ({
	showPhotoSheet(newState: boolean) {
		self.isPhotoSheetOpen = newState;
	},
	setSelectedPanelId(panelId: string | null) {
		self.selectedPanelId = panelId;
	},
	setSelectedSummaryMapMarker(panelId: string | null) {
		self.selectedSummaryMapMarker = panelId;
	},
	setIsMapFullscreen(isMapFullscreen: boolean) {
		self.isMapFullscreen = isMapFullscreen;
	}
}))
.actions((self) => ({
	openPhotoSheet(GUIDUnique:string) {
		if (!self.isPhotoSheetOpen || self.selectedPanelId !== GUIDUnique) {
			self.setSelectedPanelId(GUIDUnique);
			self.showPhotoSheet(true);
			window.document.body.style.overflowY = 'hidden';
		}
  }
}))
.views((self) => ({
	get selectedPanel() {
		return self.CampaignSummary.PanelSummary.find((panel:IPanelSummary) => panel.GUIDUnique === self.selectedPanelId) || null;
	}
}));

export interface IRootStore extends Instance<typeof RootStore> {};

const MobxContext = createContext<IRootStore>(RootStore.create());

export default MobxContext;
