export async function loginAsync(username:string, password:string) {
  const endpoint = process.env.REACT_APP_GEOTRAK_LOGIN_ENDPOINT || '';
  const response = await fetch(
    endpoint,
    {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({
        Username: username,
        Password: password
      }),
    }
  );

  if (response.status !== 200) {
    return { status: response.status, error: true, message: response.statusText };
  }

  const data = await response.json();

  if (data.UserAuth === 'Failure') {
    return { status: 401, error: true, message: 'Wrong username/password' };
  }

  return Promise.resolve(data);
}

export async function logoutAsync() {
  return Promise.resolve(true);
}