import { flow } from 'mobx-state-tree';

import { loginAsync, logoutAsync } from 'services/AuthServices';

function AuthActions(self:any) {
  return ({
    login: flow(function* _login(username, password, remember) {
      self.error = undefined;
      try {
        const { UserAuth, TokenId, UserId, message } = yield loginAsync(username, password);
        if (UserAuth === 'Success') {
          self.startSession({ TokenId, UserId, remember });
        } else {
          throw new Error(message);
        }
      } catch (ex:any) {
        self.error = ex.message;
        self.endSession();
      }
    }),
    logout: flow(function* _login() {
      yield logoutAsync();
      self.endSession();
    }),
    startSession: function({ TokenId, UserId, remember }: { TokenId:string, UserId:string, remember:boolean }) {
      self.cookies.set(self.cookieName, {
        token: TokenId,
        user: UserId
      }, {
        path: '/',
        maxAge: 60*60*(remember ? (24*7) : 1), // seconds - 1 hour or 7 days
        secure: true,
      });
    },
    endSession: function() {
      self.cookies.remove(self.cookieName);
    },
    hasDashboardCookie: function() {
      return !!self.cookies.get(self.cookieName)?.token;
    },
    getDashboardCookie: function() {
      return self.cookies.get(self.cookieName);
    }
  })
}

export default AuthActions;