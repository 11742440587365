import { getRoot, Instance, types } from 'mobx-state-tree';

const PanelImages = types.model({
  ImageUrl: types.optional(types.string, ''),
  ImageType: types.optional(types.string, ''),
  ImageUploadDate: types.optional(types.string, ''),
  ImageInstallDate: types.optional(types.string, ''),
});

const PanelMeasurement = types.model({
  PrimaryTarget: types.enumeration(['Y','N']),
  TargetName: types.optional(types.string, ''),
  MarketName: types.optional(types.string, ''),
  StartDate: types.optional(types.string, ''),
  EndDate: types.optional(types.string, ''),
  PeriodDays: types.optional(types.number, 0),
  Imp: types.optional(types.number, 0),
  ImpInMkt: types.optional(types.number, 0),
  ImpTarget: types.optional(types.number, 0),
  ImpTargetInMkt: types.optional(types.number, 0),
});

export const PanelSummary = types.model({
  GUIDUnique: types.identifier,
  GUID: types.string,
  RowNumber: types.optional(types.number, 0),
  PlanName: types.optional(types.string, ''),
  PlanMarketName: types.optional(types.string, ''),
  PlanStartDate: types.optional(types.string, ''),
  PlanEndDate: types.optional(types.string, ''),
  FormatName: types.optional(types.string, ''),
  Size: types.optional(types.string, ''),
  VendorBoardNbr: types.optional(types.string, ''), // Panel ID
  GeoPathId: types.optional(types.number, 0), // TAB Id
  Address: types.optional(types.string, ''),
  Direction: types.optional(types.string, ''),
  Facing: types.optional(types.string, ''),
  BoardRead: types.optional(types.string, ''),
  Illumination: types.optional(types.string, ''),
  Latitude: types.optional(types.number, 0),
  Longitude: types.optional(types.number, 0),
  MapDirectional: types.optional(types.string, ''),
  ImageUrls: types.array(PanelImages),
  Measurements: types.array(PanelMeasurement),
})
.views((self) => ({
  get ActiveMeasurement() {
    const root:any = getRoot(self);
    return self.Measurements.find(m => m.TargetName === root.CampaignSummary.SelectedTarget)
  }
}))
.views((self) => ({
  get MeasurementsTotalImpressions() {
    return self.Measurements.length ? self.Measurements[0].Imp : 0;
  },
  get ActiveMeasurementImpInMkt() {
    return self.ActiveMeasurement?.ImpInMkt || 0;
  },
  get ActiveMeasurementImpTarget() {
    return self.ActiveMeasurement?.ImpTarget || 0;
  },
  get ActiveMeasurementImpTargetInMkt() {
    return self.ActiveMeasurement?.ImpTargetInMkt || 0;
  },
}));

export default PanelSummary;

export interface IPanelSummary extends Instance<typeof PanelSummary> {}
export interface IPanelImages extends Instance<typeof PanelImages> {}
export interface IPanelMeasurement extends Instance<typeof PanelMeasurement> {}
