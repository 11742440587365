import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { LazyMotion, domAnimation } from 'framer-motion';

import Header from 'components/Header';
import SupportLink from 'components/SupportLink';

import MobxContext from 'models/Root';

import './ErrorPage.css';

function ErrorPage() {
  const root = useContext(MobxContext);
  const location = useLocation();
  const { state }:{ state:any } = location;

  const logout = async () => {
		await root.Authentication.logout();
    window.location.href = '/login' + location.search;
	}

  return (
    <LazyMotion features={domAnimation}>
      <div className='error-page'>
        <Header dataIsLoaded logoutFn={logout} />
        <div className='message'>
          {state?.error || 'Service Unavailable'}
        </div>
        <div className='support-link-wrapper'>
          <SupportLink text='Please contact your Client Success Team' />
        </div>
        <div className='art'/>
      </div>
    </LazyMotion>
  );
}

export default ErrorPage;
