export function MakeEventListenersPassive() {
	if (typeof EventTarget !== 'undefined') {
		let supportsPassive = false

		try {
			// Test via a getter in the options object to see if the passive property is accessed
			const listenerOptions = Object.defineProperty({}, 'passive', {
				get: () => {
					supportsPassive = true
					return null
				},
			})
			window.addEventListener('passiveListener', null, listenerOptions)
			window.removeEventListener('passiveListener', null, listenerOptions)
		} catch (e) {}

		const func = EventTarget.prototype.addEventListener

		EventTarget.prototype.addEventListener = function (eventType, fn) {
			this.func = func
			this.func(eventType, fn, supportsPassive ? { passive: false } : false)
		}
	}
}
