import { lazy, Suspense, useContext, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { configure } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useIdle } from '@mantine/hooks';

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import MobxContext, { IRootStore } from 'models/Root';

import { readQueryParams } from 'utils/Url';

import './App.css';

const Main = lazy(() => import('components/Main'));
const LoginPage = lazy(() => import('pages/Login/LoginPage'));
const ReviewImages = lazy(() => import('pages/ReviewImages'));
const NotFound = lazy(() => import('pages/NotFound/NotFound'));
const UnderMaintenance = lazy(() => import('pages/UnderMaintenance'));
const ErrorPage = lazy(() => import('pages/ErrorPage'));
const renderLoader = () => <div></div>;

if (['staging, production'].includes(process.env.REACT_APP_ENV || '')) {
  // Sentry configuration
  // Sentry.captureException(error);
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [new BrowserTracing()],

    // It's recommended to this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_ENV,
  });
}

// MST configuration
configure({
  enforceActions: "always",
  /* computedRequiresReaction: true,
  reactionRequiresObservable: true,
  observableRequiresReaction: true,
  disableErrorBoundaries: true */
});
//const store = RootStore.create();

function App() {
  const store = useContext(MobxContext);
  if (process.env.REACT_APP_IS_MAINTENANCE === 'Y') {
    return (
      <Suspense fallback={renderLoader()}>
        <UnderMaintenance />
      </Suspense>
    );
  }

  return (
    <MobxContext.Provider value={store}>
      <div className="App">
        <Suspense fallback={renderLoader()}>
          <Routes>
            <Route path='/login' element={
              <LoginPage />
            } />
            <Route path='/' element={
              <RequireAuth store={store}>
                <Main />
              </RequireAuth>
            } />
            <Route path='/review-poptracker-images' element={
              <ReviewImages />
            } />
            <Route path='/maintenance' element={
              <UnderMaintenance />
            } />
            <Route path='/support' element={
              <ErrorPage />
            } />
            <Route path='*' element={
              <NotFound />
            } />
          </Routes>
        </Suspense>
      </div>
    </MobxContext.Provider>
  );
}

export default observer(App);

const RequireAuth = observer(({ children, store }: { children: JSX.Element, store:IRootStore }) => {
  let location = useLocation();
  const { ClientID, CampaignID } = readQueryParams(location.search);
	const targetedLoginRoute = `/login${ClientID && CampaignID ? '?ClientID=' + ClientID + '&CampaignID=' + CampaignID : ''}`;
  // Detect user is idle after 8 hours
  const userIsIdle = useIdle(1000*60*60*8, { initialState: false });

  useEffect(() => {
    if (userIsIdle && !store.Authentication.hasDashboardCookie()) {
      store.Authentication.endSession();
    }
  }, [userIsIdle, store.Authentication]);

  if (!store.Authentication.hasDashboardCookie()) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={targetedLoginRoute} state={{ from: location }} replace />;
  }

  return children;
});
