import { flow, getRoot } from "mobx-state-tree";
import { IPanelSummary, IPanelImages, IPanelMeasurement } from "models/PanelSummary/PanelSummary";
import { IPlanSummary } from "models/PlanSummary/PlanSummary";
import { IAggregationPlan } from "./CampaignSummaryModel";

import { fetchCampaignData } from "services/CampaignSummaryService";
import fetchImages from 'services/POPTrackerService';

import { SELECT_TARGET } from "utils/Constants";
import { getFormatColor } from 'utils/Icons';

function CampaignSummaryActions(self: any) {
  return {
    /**
     * @param {string} ClientID
     * @param {string} CampaignID
     * @description Calls services from GeoTrak and POPTracker to retrieve campaign data and photos
     * If GeoTrak response fails and it's an expired session, it's expected to see the Login page
     * If GeoTrak response fails and it's not an expired session, it's expected to see Under Maintenance
     * If POPTracker response fails, it's expected to see a global informational message
     */
    fetchCampaignData: flow(function* _fetchCampaignData(ClientID, CampaignID) {
      self.error = undefined;
      const targetsAggr:string[] = [SELECT_TARGET];
      const mediaTypes = new Map();
      const processPanelAggregation = (panel:IPanelSummary) => {
        if (!mediaTypes.has(panel.FormatName)) {
          mediaTypes.set(panel.FormatName, {
            type: panel.FormatName,
            plans: [],
            color: getFormatColor(panel.FormatName)
          });
        }

        const { plans }:{ plans:IAggregationPlan[] } = mediaTypes.get(panel.FormatName);
        const planExists = !!plans.find((ap:IAggregationPlan) => ap.planName === panel.PlanName);
        if(!planExists) {
          plans.push({ planName: panel.PlanName, impressions: 0, count: 0 });
        }

        const mediaPlan = plans.find((ap:IAggregationPlan) => ap.planName === panel.PlanName);
        if (mediaPlan) { // this should always be true
          mediaPlan.impressions += (panel.Measurements.length ? Number(panel.Measurements[0].Imp) : 0);
          mediaPlan.count += 1;
        }
      }

      try {
        const root:any = getRoot(self);
        const { user, token } = root.Authentication.getDashboardCookie()
        
        const [responseData, popTrackerResponse] = yield Promise.all([
          fetchCampaignData(user, token, ClientID, CampaignID),
          fetchImages(CampaignID)
        ])

        if (responseData.error) {
          throw new Error(responseData.message);
        }

        if (popTrackerResponse.error) {
          // Don't throw
          // allow to display placeholder images
          // and display a warning message
          if (popTrackerResponse.status === 200) {
            self.error = popTrackerResponse.message;
          } else {
            self.error = 'Images are not available at the moment. Please check back later.'
          }
        }
  
        const mappedData = {
          ...responseData,
          ClientName: popTrackerResponse.client || '',
          CampaignBudget: Number(responseData.CampaignBudget),
          TotalMediaPlanCost: Number(responseData.TotalMediaPlanCost),
          PlanSummary: mapPlans(responseData.PlanSummary),
          PanelSummary: mapPanels(
            responseData.PanelSummary,
            popTrackerResponse.units || [],
            processPanelAggregation,
            targetsAggr
          ),
        }
  
        // Assign to self tree node
        Object.assign(self, mappedData);
  
        self.PlanPickerOptions = mapToPlanOptions(mappedData.PanelSummary);
        self.Targets = targetsAggr;
        self.SelectedTarget = targetsAggr[0];
        self.Aggregations.MediaTypes = Array.from(mediaTypes.values());
      } catch (ex:any){
        // const message = ` ${ex.message} : Error while fetching campaign data`;
        console.error(ex.message);
        self.error = ex.message;
      }
    }),
    updateSearchLocationCriteria: function (criteria:string) {
      self.SearchLocationCriteria = criteria;
    },
    setSelectedTarget: function(target:string) {
      self.SelectedTarget = target
    }
  }
}

export default CampaignSummaryActions;

const facingMapper = new Map([
  ['S/B','North'],
  ['N/B','South'],
  ['W/B','East'],
  ['E/B','West']
]);

interface IUnitWithImage {
  Guid: string;
  dateInstalled: string;
  images: IPanelImages[];
}
function mapPanels (
  panels:IPanelSummary[], 
  unitsWithImages:IUnitWithImage[], 
  processPanelAggregation:(panel:IPanelSummary)=>void,
  targetsAggr:string[]
) {
  return panels.map((panel:IPanelSummary, i:number) => {
    processPanelAggregation(panel);

    const panelUnit = unitsWithImages.find(unit => unit.Guid.toLowerCase() === panel.GUID.toLowerCase());
    const imgList = panelUnit?.images?.map(
      imgObj => ({ ...imgObj, ImageInstallDate: panelUnit?.dateInstalled || '' })
    ) || [];

    return {
      ...panel,
      GUIDUnique: panel.GUID + i,
      RowNumber: Number(panel.RowNumber),
      GeoPathId: Number(panel.GeoPathId),
      Latitude: Number(panel.Latitude),
      Longitude: Number(panel.Longitude),
      ImageUrls: imgList,
      Facing: facingMapper.get(panel.Direction),
      Measurements: mapMeasurements(panel.Measurements, targetsAggr),
    }
  });
}

function mapMeasurements(measurements:IPanelMeasurement[], targetsAggr:string[]) {
  return measurements.map(m => {
    if (!targetsAggr.find(t => t === m.TargetName)) {
      targetsAggr.push(m.TargetName);
    }

    return {
      ...m,
      PeriodDays: Number(m.PeriodDays || 0),
      Imp: Number(m.Imp || 0),
      ImpInMkt: Number(m.ImpInMkt || 0),
      ImpTarget: Number(m.ImpTarget || 0),
      ImpTargetInMkt: Number(m.ImpTargetInMkt || 0),
    }
  })
}

function mapPlans (plans:IPlanSummary[]) {
  return plans.map((p: IPlanSummary) => ({
      ...p,
      PlanBudget: Number(p.PlanBudget)
    })
  );
}

function mapToPlanOptions(panels: IPanelSummary[]) {
  const mapper = new Map();
  mapper.set(0, { id: 0, value: 'All Plans'});

  panels.forEach(panel => {
    if (!mapper.has(panel.PlanName)) {
      const newId = mapper.size + 1;
      mapper.set(panel.PlanName, { id: newId, value: panel.PlanName });
    }
  });

  return Array.from(mapper.values())
}
