const currencyFormat = { style: 'currency', currency: 'USD' };
export const currencyFormatter = new Intl.NumberFormat('en-US', currencyFormat);
export const numberFormatter = new Intl.NumberFormat('en-US');

export function createFormattedDate(stringDate: string) {
  const [month, day, year] = stringDate.split('/');
  const date = new Date();
  date.setUTCFullYear(Number(year), Number(month), Number(day));
  return date;
}

export function escapeRegExp(text:string) {
  return text.replace(/[.*+\-?^${}()|[\]\\]/g,'\\$&'); // $& means all matching string
}