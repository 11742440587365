import { getParent, types, Instance } from 'mobx-state-tree';
import CampaignSummaryActions from './CampaignSummaryActions';

import { IMarker } from 'models/Interfaces';
import PlanSummary from 'models/PlanSummary/PlanSummary';
import PanelSummary, { IPanelSummary } from 'models/PanelSummary/PanelSummary';

import { NO_IMAGE, SELECT_TARGET } from 'utils/Constants';
import { escapeRegExp } from 'utils/Formatters';
import { getFormatColor } from 'utils/Icons';

const PickerOption = types.model({
  id: types.number,
  value: types.union(types.string, types.number),
});

const AggregationPlan = types.model({
  planName: types.string,
  impressions: types.number,
  count: types.number,
})
const MediaTypeAggregation = types.model({
  type: types.string,
  plans: types.array(AggregationPlan),
  color: types.string,
})
const Aggregations = types.model({
  MediaTypes: types.array(MediaTypeAggregation)
  // Other aggregations may come later on
})
.views((self) => ({
  get TargetedMediaTypes():IMediaTypeAggregation[] {
    const campaign = getParent(self, 1) as ICampaignSummary;
    const mediaTypes = new Map<string,IMediaTypeAggregation>();
    campaign.PanelSummary.forEach(panel => processPanelAggregation(panel, mediaTypes));
    return Array.from(mediaTypes.values());
  }
}));

export const CampaignSummary = types.model({
  ClientName: types.optional(types.string, ''),
  Advertiser: types.optional(types.string, ''),
  CampaignId: types.optional(types.string, ''),
  CampaignName: types.optional(types.string, ''),
  CampaignStart: types.optional(types.string, ''),
  CampaignEnd: types.optional(types.string, ''),
  CampaignBudget: types.optional(types.number, 0),
  TotalMediaPlanCost: types.optional(types.number, 0),
  // LISTS
  PlanSummary: types.array(PlanSummary),
  PanelSummary: types.array(PanelSummary),
  // STATE
  Targets: types.array(types.string),
  SelectedTarget: types.optional(types.string, ''),
  PlanPickerOptions: types.optional(types.array(PickerOption), [{ id: 0, value: 'All Plans'}]),
  Aggregations: types.optional(Aggregations, {}),
  SearchLocationCriteria: types.optional(types.string, ''),
  error: types.maybe(types.string),
})
.actions(CampaignSummaryActions)
.views((self) => ({
  get TotalPlannedImpressions(): number {
    return self.PanelSummary.reduce((aggr, currPanel)=> {
      if (!currPanel.Measurements.length) return aggr;
      return aggr + currPanel.MeasurementsTotalImpressions;
    }, 0);
  },
  get TargetedPlannedImpressions(): number {
    return self.PanelSummary.reduce((aggr, currPanel)=> {
      return aggr + currPanel.ActiveMeasurementImpTargetInMkt;
    }, 0);
  },
  get isTarget(): boolean {
    return self.SelectedTarget !== SELECT_TARGET;
  },
  get selectedTargetHeader(): string {
    return `${self.SelectedTarget === SELECT_TARGET ? 'No In-Market Target Audiences Selected' : self.SelectedTarget}`;
  },
  get markets(): string[] {
    const mapper = new Map();
    self.PlanSummary.forEach(plan => {
      const [marketName] = plan.PlanMarket.split(',');
      if (!mapper.has(marketName)) {
        mapper.set(marketName, marketName);
      }
    });

    return Array.from(mapper.values()).sort((a,b) => 
      a.toLowerCase().localeCompare(b.toLowerCase())
    );
  },
  get planMarkers(): IMarker[] {
    return self.PanelSummary.map((panel) => ({ 
      guid: panel.GUIDUnique, 
      panelId: panel.VendorBoardNbr, 
      latitude: panel.Latitude, 
      longitude: panel.Longitude,
      direction: panel.MapDirectional === 'Y' ? panel.Direction : '',
      format: panel.FormatName
    }));
  },
  get availableFormats(): { format:string, directional:string }[] {
    const mapper = new Map();
    self.PanelSummary.forEach(panel => {
      if (!mapper.has(panel.FormatName)) {
        mapper.set(panel.FormatName, {
          format: panel.FormatName,
          directional: panel.MapDirectional
        });
      }
    });

    return Array.from(mapper.values());
  },
  get displayedPanels() {
    if(!self.SearchLocationCriteria) return self.PanelSummary;

    const criteria = new RegExp(
      escapeRegExp(self.SearchLocationCriteria.toLowerCase()),
      'i'
    );
    return self.PanelSummary.filter(panel => {
      return panel.FormatName.trim().search(criteria) > -1 ||
        panel.PlanMarketName.trim().search(criteria) > -1 ||
        panel.VendorBoardNbr.trim().search(criteria) > -1;
    })
  },
  get summaryCarousel() {
    const images = self.PanelSummary.reduce((accumulator:IImageInfo[], curr:IPanelSummary) => {
      curr.ImageUrls.forEach(imgUrl => {
        accumulator.push({
          url: imgUrl.ImageUrl,
          type: imgUrl.ImageType,
          panelId: curr.VendorBoardNbr,
          GUIDUnique: curr.GUIDUnique,
        });
      });
      /* if (curr.ImageUrls.length) {
        accumulator.push({
          url: curr.ImageUrls[0].ImageUrl,
          panelId: curr.VendorBoardNbr,
          GUIDUnique: curr.GUIDUnique,
          type: curr.ImageUrls[0].ImageType,
        });
      } */
      return accumulator;
    }, []);
    
    if (!images.length) images.push({url: NO_IMAGE});

    return images;
  }
}))
.views((self) => ({
    get TotalCPM(): number {
      return self.TotalMediaPlanCost/(self.TotalPlannedImpressions/1000);
    },
    get TargetedCPM(): number {
      return self.TotalMediaPlanCost/(self.TargetedPlannedImpressions/1000);
    },
}));

export default CampaignSummary;

export interface ICampaignSummary extends Instance<typeof CampaignSummary> {}
export interface IPickerOptions extends Instance<typeof PickerOption> {}
export interface IAggregationPlan extends Instance<typeof AggregationPlan> {}
export interface IMediaTypeAggregation extends Instance<typeof MediaTypeAggregation> {}

interface IImageInfo {
  url: string;
  panelId?: string;
  GUIDUnique?: string;
  type?: string;
}

// TODO this logic is duplicated in the actions
// There's probably a better way to do it
const processPanelAggregation = (panel:IPanelSummary, mediaTypes:Map<string,any>) => {
  if (!!panel.ActiveMeasurement) {
    if (!mediaTypes.has(panel.FormatName)) {
      mediaTypes.set(panel.FormatName, {
        type: panel.FormatName,
        plans: [],
        color: getFormatColor(panel.FormatName)
      });
    }

    const { plans }:{ plans:IAggregationPlan[] } = mediaTypes.get(panel.FormatName);
    const planExists = !!plans.find((ap:IAggregationPlan) => ap.planName === panel.PlanName);
    if(!planExists) {
      plans.push({ planName: panel.PlanName, impressions: 0, count: 0 });
    }

    const mediaPlan = plans.find((ap:IAggregationPlan) => ap.planName === panel.PlanName);
    if (mediaPlan) { // this should always be true
      mediaPlan.impressions += panel.ActiveMeasurementImpTargetInMkt;
      mediaPlan.count += 1;
    }
  }
}