export default async function fetchImages(CampaignId:string, Page:number=1) {
  const endpoint = process.env.REACT_APP_POPTRACKER_UNITS_ENDPOINT || '';
  const popTrackerToken = process.env.REACT_APP_POPTRACKER_API_TOKEN || '';
  const params:RequestInit = {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Authorization': 'Token ' + popTrackerToken,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
    },
    body: JSON.stringify({CampaignId,Page,RecordsPerPage:100})
  }

  try {
    const response = await fetch(
      endpoint,
      params
    );
  
    if (response.status !== 200) {
      return { status: response.status, error: true, message: response.statusText };
    }
  
    const data = await response.json();
  
    if (!data.Data?.length) {
      return { status: response.status, error: true, message: 'No images were found for this campaign' };
    }
  
    const client = data.Data[0].client || '';
    const units = data.Data
      .flatMap((clientData:any) => clientData.campaigns || [])
      .flatMap((campaign:any) => campaign.units || []);

    // call recursively for next pages if needed
    if (data.TotalRecords > 0 && data.Page < data.TotalPages) {
      const extraPage:any = await fetchImages(CampaignId, Page + 1);
      if (extraPage.error) {
        return extraPage;
      }
      units.concat(extraPage.units);
    }

    const { campaignID = undefined, campaignName = undefined } = data.Data[0].campaigns?.length ?
      data.Data[0].campaigns[0] :
      {};
    const campaign = { campaignID, campaignName }
    
    return { client, units, campaign };
  } catch(ex:any) {
    return { status: 500, error: true, message: ex.message };
  }
}
