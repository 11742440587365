// import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from 'react-cookie';

import App from './App';
import ErrorBoundary from 'components/ErrorBoundary';

import { MakeEventListenersPassive } from 'utils/GlobalHelper';

import reportWebVitals from './reportWebVitals';

import './index.css';

MakeEventListenersPassive();

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

root.render(
  <BrowserRouter>
    <ErrorBoundary>
      {/* Google map errors: Cannot read properties of undefined (reading 'emit') */}
      {/* <StrictMode>
        <CookiesProvider>
          <App />
        </CookiesProvider>
      </StrictMode> */}
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </ErrorBoundary>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
