import { Instance, types } from 'mobx-state-tree';

export const PlanSummary = types.model({
  PlanId: types.identifier,
  PlanName: types.optional(types.string, ''),
  PlanStart: types.optional(types.string, ''),
  PlanEnd: types.optional(types.string, ''),
  PlanBudget: types.optional(types.number, 0),
  PlanMarket: types.optional(types.string, ''),
});

export default PlanSummary;

export interface IPlanSummary extends Instance<typeof PlanSummary> {}
