import React from "react";
import ErrorPage from "pages/ErrorPage";

import MobxContext, { RootStore, IRootStore } from 'models/Root';

export let upperStore: IRootStore;

interface IProps {
  children?: React.ReactNode;
}
interface IErrorBoundaryState {
  hasError: boolean;
}
class ErrorBoundary extends React.Component<IProps, IErrorBoundaryState> {
  store: IRootStore;

  constructor(props:any) {
    super(props);
    this.state = { hasError: false };
    this.store = RootStore.create();
    upperStore = this.store;
  }

  static getDerivedStateFromError(error:any) {
    // Update the state so the next render cycle can display the backup interface
    return { hasError: true };
  }

  componentDidCatch(error:any, errorInfo:any) {
    // Here we can send log errors to a service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <MobxContext.Provider value={this.store}>
          <ErrorPage />
        </MobxContext.Provider>
      );
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;
