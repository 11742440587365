export const transformFormatName = (format:string):string => format.replace(/(\(|\)|\s*)/g, '').toLowerCase();

export function getIconName(
  format:string, 
  direction?:string, 
  getDefault?:boolean,
  size:'small'|'medium'='medium',
) {
  let isGeneric = false;
  let iconType;
  
  if (imageryMapper.has(format)) {
    iconType = imageryMapper.get(format)?.iconType;
  } else {
    iconType = 'pin';
    isGeneric = true;
  }

  let iconSuffix = '';

  if (getDefault && !isGeneric) {
    iconSuffix = '-right';
  } else if (direction && !isGeneric) {
    iconSuffix = `-${directionMapper.get(direction.split('/')[0])}`;
  } // else no suffix

  return `/maps/${size}/${iconType}${iconSuffix}.png`;
}

const defaultColor = '#A6A9AA';

export function getFormatColor(format:string):string {
  const formatName = format;
  if (imageryMapper.has(formatName)) {
    return imageryMapper.get(formatName)?.color || '';
  } else {
    return defaultColor;
  }
}
/* export function iconUrlToHighlight(regularUrl:string) {
  return regularUrl.replace('/small/', '/small_highlight/').replace('.png', '_highlight.png');
} */

interface IIconType {
  iconType: string,
  color: string,
};
const imageryMapper = new Map<string,IIconType>([
  ['8 Sheet Poster', { iconType: '8sheetposter', color: '#9CD1CA'}],
  ['Aerial', { iconType: 'aerial', color: '#817491'}],
  ['Airport', { iconType: 'airport', color: defaultColor}],
  ['Arena Rink', { iconType: 'arenarink', color: '#AD7D96'}],
  ['Backlights', { iconType: 'backlights', color: '#DED5EA'}],
  ['Banner', { iconType: 'banner', color: '#B690B6'}],
  ['Barn', { iconType: 'barn', color: defaultColor}],
  ['Benches', { iconType: 'bench', color: defaultColor}],
  ['Beverage Talker', { iconType: 'beveragetalker', color: '#F47D30'}],
  ['Bike Kiosks', { iconType: 'bikekiosks', color: defaultColor}],
  ['Bike Pillar', { iconType: 'bikepillar', color: defaultColor}],
  ['Bulletin', { iconType: 'bulletin', color: '#38BDE3'}],
  ['Carts', { iconType: 'cart', color: defaultColor}],
  ['Cinema', { iconType: 'cinema', color: defaultColor}],
  ['City Information Panels', { iconType: 'cityinfopanels', color: '#FBCA04'}],
  ['Coffee Shop', { iconType: 'coffeeshop', color: defaultColor}],
  ['Diaroma', { iconType: 'diaroma', color: '#41B576'}],
  ['Digital', { iconType: 'digital', color: '#27A493'}],
  ['Digital Backlights', { iconType: 'digitalbacklights', color: '#E384AA'}],
  ['Digital Bike Kiosks', { iconType: 'digitalbikekiosks', color: '#92CCF1'}],
  ['Digital Bulletin', { iconType: 'digitalbulletin', color: '#D96BA3'}],
  ['Digital City Information Panels', { iconType: 'digitalcityinfopanels', color: '#A538BD'}],
  ['Digital Junior Poster', { iconType: 'digitaljuniorposter', color: '#F7D6C1'}],
  ['Digital Kiosks', { iconType: 'digitalkiosks', color: defaultColor}],
  ['Digital Newsstand Posters', { iconType: 'digitalnewsstandposters', color: '#8CBFA5'}],
  ['Digital Newstands', { iconType: 'digitalnewstands', color: '#E390CF'}],
  ['Digital Phone Kiosks', { iconType: 'digitalphonekiosks', color: '#CC2EA5'}],
  ['Digital Posters', { iconType: 'digitalposters', color: '#CC4D68'}],
  ['Digital Transit Shelters', { iconType: 'digitaltransitshelters', color: '#87BFCC'}],
  ['Digital Urban Panels', { iconType: 'digitalurbanpanels', color: '#8C456B'}],
  ['Digital Walls', { iconType: 'digitalwalls', color: '#503D67'}],
  ['Door Hanger', { iconType: 'doorhanger', color: '#375496'}],
  ['DOT Sign', { iconType: 'dotsign', color: '#5581E4'}],
  ['Floor Talkers', { iconType: 'floortalkers', color: defaultColor}],
  ['Gas Station', { iconType: 'gasstation', color: defaultColor}],
  ['Gas Station Radio', { iconType: 'gasstationradio', color: defaultColor}],
  ['Gas Station TV', { iconType: 'gasstationtv', color: defaultColor}],
  ['Health Club Video', { iconType: 'healthclubvideo', color: '#BBE9EE'}],
  ['Jr. Bulletin', { iconType: 'jrbulletin', color: '#2A5C5F'}],
  ['Mobile', { iconType: 'mobile', color: defaultColor}],
  ['Mobile Vehicle', { iconType: 'mobilevehicle', color: defaultColor}],
  ['Phone Board', { iconType: 'phoneboard', color: defaultColor}],
  ['Phone Kiosks', { iconType: 'phonekiosks', color: defaultColor}],
  ['Poster', { iconType: 'poster', color: '#3691AB'}],
  ['Transit (Other)', { iconType: 'transit', color: '#000000'}],
  ['Transit Shelters (TSA)', { iconType: 'transitshelterstsa', color: '#4FBFD1'}],
  ['Tri-Vision', { iconType: 'trivision', color: '#DEC7D1'}],
  ['Urban Panels', { iconType: 'urbanpanels', color: '#732A73'}],
  ['Wallscape', { iconType: 'wallscape', color: '#BEBEBE'}],
]);

const directionMapper = new Map<string,string>([
  ['S', 'top'],
  ['N', 'bottom'],
  ['E', 'left'],
  ['W', 'right'],
]);
