import { m, AnimatePresence } from 'framer-motion';

import './Header.css';

interface IHeaderProps {
  dataIsLoaded: boolean;
  logoutFn?: () => void;
	customLogoPath?: string;
}
function Header({ dataIsLoaded, logoutFn, customLogoPath }: IHeaderProps) {
  return (
    <header className={`app-header${dataIsLoaded ? ' w-boarder' : ''}`}>
      {
				customLogoPath ?
				<img src={customLogoPath} alt='Talon logo' className='custom-talon-logo' width={110} height={62} /> :
				<TalonAmericaAnimated dataIsLoaded={dataIsLoaded} />
			}
      {logoutFn && dataIsLoaded ?
				<m.div
					initial={{
						opacity: 0
					}}
					animate={{
						opacity: 1
					}}
					transition={{
						delay: .5
					}}>
					<m.div
						onClick={logoutFn}
						className='log-out'
						whileHover={{ scale: 1.1 }}>
							LOG OUT
					</m.div>
				</m.div>
        : null
      }
    </header>
  )
}

export default Header;

function TalonAmericaAnimated({ dataIsLoaded }:{ dataIsLoaded: boolean }) {
	const svgPath = 'M86.17,90.34c-.17,1.27-.26,2.54-.27,3.82,0,6.17,3.16,9.64,8.89,9.64,8.77,0,18.19-5.75,25.28-12.46,1.35,7.28,6.26,12.42,12.15,12.42,4.53,0,10.82-2.16,23.95-15.55l.31,.29c-.43,2.4-.65,4.84-.65,7.28,0,5.13,2.8,7.98,8.22,7.98,7.18,0,16.75-7.6,23.33-14.97h0c-.26,1.7-.42,3.42-.47,5.15,0,6.17,3.16,9.64,8.89,9.64,6.71,0,16.66-5.31,24.48-13.28,2.29,8.08,9.79,13.56,18.19,13.3,15.7,0,39.47-14.15,42.76-37.63,3.33-4.98,7.46-9.09,8.93-9.09,1,.09,1.74,.97,1.66,1.96-.11,1.67-.36,3.34-.76,4.97l-2.18,10.15h0l-6.02,27.88h19.15l5.24-24.48c9.58-12.73,15.15-18.82,18.01-18.97,.75,0,1.51,.91,1.51,2.11,0,1.08-.13,2.16-.4,3.2l-8.93,38.19h19.19s12.73-45.71,12.73-54.56c0-4.37-2.56-8.73-8.29-8.73-7.38,0-16.37,7.28-30.61,24.99l-.31-.31c1.36-4.91,2.17-9.95,2.42-15.04,0-6.18-2.87-9.64-7.86-9.64-6.6,0-14.19,5.46-23.52,17.28-1.53-10.69-8.66-17.28-19.19-17.28-20.44,0-42.92,20.01-42.92,43.83v.45c-4.4,4.67-7.38,6.71-9.58,6.71-1.82,0-2.76-1.51-1.71-7.17L223.58,3.24h-19.13l-13.66,67.13c-.2,1.04-.42,2.09-.64,3.22-3.64,8.17-10.6,14.19-12.53,14.19-1.2,0-1.51-1.47-.93-4.71l8.91-44.16h-6.31c-31.43,0-55.16,21.35-59.02,42.49-3.27,3.49-6.84,6.89-9.75,6.89-1.82,0-2.76-1.53-1.66-7.28l12.29-60.29h37.16l3.64-17.11H66.73l-3.47,17.1h36.38l-13.46,69.64ZM253.79,49.05c6.77,0,10.26,4.78,10.26,15.9,0,16.66-9.09,28.28-18.06,28.28-5.46,0-9.46-5.55-9.46-15.5,0-14.46,8.62-28.68,17.26-28.68Zm-87.17,0l-5.67,27.74c-7.38,8.13-10.91,10.53-13.11,10.53-3.33,0-8.28-3.91-8.28-10.53,0-16.26,11.68-27.65,27.06-27.65v-.09Z';
	const solidDark = '#222325';
	const transparent = '#00000000';

  return (
		<svg xmlns="http://www.w3.org/2000/svg" className='app-logo' width="150" height="60" viewBox="0 0 405.87 171.48" role='banner'>
				<AnimatePresence>
					{dataIsLoaded ? (
						<>
							<path
								className='svg-logo-path'
								d={svgPath}
								fill={solidDark}
								stroke={solidDark}
								strokeWidth='2'
								strokeLinecap='round'
							/>
							<line className='svg-line' x1='69.3' y1='117.78' x2='343.3' y2='117.78'/>
							<text className='svg-text' transform='translate(124.35 155.5)' fill={solidDark}>
								<tspan className='svg-span-1' x='0' y='0'>AMERICA</tspan>
							</text>
						</>
					) : (
						<>
							<m.path
								className='svg-logo-path'
								d={svgPath}
								stroke={solidDark}
								strokeWidth='2'
								strokeLinecap='round'
								initial={{ pathLength: 0, fill: transparent }}
								animate={{
									pathLength: 1,
									fill: [transparent,transparent,transparent,solidDark]
								}}
								transition={{ 
									duration: 4,
									repeat: Infinity,
									ease: 'easeInOut'
								}}
							/>
							<m.line className='svg-line' x1='69.3' y1='117.78' x2='343.3' y2='117.78'
								initial={{ x2: 69.3 }}
								animate={{ x2: [null, 343.3] }}
								transition={{ 
									duration: 4,
									repeat: Infinity,
									ease: 'easeInOut',
									times: [0.5, 1]
								}}/>
							<m.text className='svg-text'
								initial={{
									fill: transparent,
									transform: 'translateX(124.35px) translateY(200px)'
								}}
								animate={{
									fill: [null,solidDark],
									transform: 'translateX(124.35px) translateY(155.5px)'
								}}
								transition={{ 
									duration: 4,
									repeat: Infinity, 
									repeatType: 'loop', 
									ease: 'easeInOut',
									times: [0.5, 1]
								}}>
								<m.tspan className='svg-span-1' x='0' y='0'>LOADING</m.tspan>
							</m.text>
						</>
					)}
				</AnimatePresence>
    </svg>
  )
}