export const SELECT_TARGET = 'No Target';
export const TOTAL_IMPRESSIONS = 'Total Impressions';

export const FETCH_CAMPAIGN_ERROR = 'Fetch Campaign Error';
export const NO_IMAGE = 'No Image';

export enum API_500_CODES {
  // Might be an incorrect copy&paste of the URL, or the backend simply crashing
  SERVER_ERROR = 'Oops! We might be missing some piece of data to work with.'
};

export enum API_400_CODES {
  CAMPAIGN_AUTH_FAIL = 'The URL provided is incorrect. Please contact The Client Success team for a new URL.',
  CLIENT_AUTH_FAIL = 'The URL provided is incorrect. Please contact The Client Success team for a new URL.',
  SESSION_EXPIRED = 'The session expired. Please sign in again.',
  // We don't request pages, so any 404 here will probably mean that the service is offline
  SERVICE_OFFLINE = 'The service is momentarily offline.'
};