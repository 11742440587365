import { Location } from 'react-router-dom';
import osLogo from 'assets/OS_logo.png';
import osLogoWhite from 'assets/OS_logo_white.png';

export function readQueryParams(search:string) {
	if (!search) return {};

	const unescaped = decodeURIComponent(search.replace('&amp;', '&').replace('?',''));
	const bits = unescaped.split('&');
	return bits.reduce((prev:any, curr:string) => {
		const [key,value] = curr.split('=');
		prev[key.trim()] = value.trim();
		return prev;
	}, {});
}

/**
 * Determine whether the user was in a different page or came in
   directly to this one
 * @param location a location object from the useLocation hook (react-router-dom)
 * @returns a string with the correct redirection value
 */
export function getPreviousLocation(location:Location): string {
  let from = '/';
  const { state:previousLocation }:{ state: any } = location;

  if (previousLocation?.from) {
    from = previousLocation.from?.pathname || '/';
    from += previousLocation.from?.search || '';
  } else {
    from += location.search || '';
  }

  return from;
}

export function getUrlParams(location:Location) {
  let prevLoc = getPreviousLocation(location);
  if (prevLoc.startsWith('/')) prevLoc = prevLoc.replace('/', '');

  return readQueryParams(prevLoc);
}

export function getCustomLogoPath(clientID:string, lightTheme:boolean=false): string|undefined {
  const osClients = process.env.REACT_APP_CLIENTS_OS;
  if (osClients?.split(',').includes(clientID)) {
    return lightTheme ? osLogoWhite : osLogo;
  }
  return;
}

export function getLogoPath(clientID:string, lightTheme:boolean=false): string {
  return getCustomLogoPath(clientID, lightTheme) || './Talon_America_logo.png';
}
