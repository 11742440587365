import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { Cookies } from 'react-cookie';
import AuthActions from './AuthActions';

const cookies = new Cookies();
interface ICookies extends Instance<Cookies>{};
const CookiesType = types.custom<string, ICookies>({
    name: "CookiesType",
    fromSnapshot() {
        return cookies;
    },
    toSnapshot() {
        return cookies.toString();
    },
    isTargetType(value: any | any): boolean {
        return value instanceof Cookies;
    },
    getValidationMessage(value: any): string {
        if (Object.getPrototypeOf(value) === Object.getPrototypeOf(cookies)) return '';// OK
        return `'${value}' doesn't look like a valid cookie`
    }
})
export const AuthenticationStore = types.model({
  isAuthenticated: false, // @Deprecated
  cookies: types.optional(CookiesType, cookies),
  cookieName: 'talon-dashboard-app',
  error: types.maybe(types.string),
})
.actions(AuthActions);

export interface IAuthentication extends Instance<typeof AuthenticationStore> {};
export interface IAuthenticationSnapshot extends SnapshotOut<typeof AuthenticationStore> {};

export default AuthenticationStore;