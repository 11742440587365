import { useState } from 'react';
import { Popover, CloseButton } from '@mantine/core';
import { m } from 'framer-motion';

import './SupportLink.css';

function SupportLink({ style, text }: { style?: any, text?: string }) {
  const [opened, setOpened] = useState(false);

  return (
    <Popover
      opened={opened}
      onClose={() => setOpened(false)}
      trapFocus={false}
      position='bottom'
      transition='pop-top-left'
      width={400}
      shadow='lg'
    >
      <Popover.Target>
        <m.p
          style={style}
          className='cs-action'
          whileTap={{ scale: 0.99 }}
          onClick={() => setOpened(true)}
        >
          {text || 'Need Support?'}
        </m.p>
      </Popover.Target>
      <Popover.Dropdown>
        <CloseButton
          aria-label="Close Support Popup"
          size='sm'
          onClick={() => setOpened(false)}
          className='cs-link-close-btn' />
        <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`} className='cs-link'>
          Please contact your Client Success Team with any dashboard related questions
        </a>
      </Popover.Dropdown>
    </Popover>
  );
}

export default SupportLink;
